import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../../Components/ProjectThaiHub/Home";
import NotFoundPage from "../../Components/Static/NotFoundPage";
// import Main from "../../Components/ProjectThaiHub/Main";
import PrivacyPolicy from "../../Components/SwapNow/PrivacyPolicy/PrivacyPolicy";
import TermAgreement from "../../Components/SwapNow/TermAgreement/TermAgreement";
import AppleAuthContainer from "../AppleAuthContainer";

function RouteBuilder() {
	const routes = (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />}></Route>
				<Route path="/auth" element={<AppleAuthContainer />}></Route>
				<Route
					path="swapnow/term-agreement"
					element={<TermAgreement />}
				></Route>
				<Route
					path="swapnow/privacy-policy"
					element={<PrivacyPolicy />}
				></Route>
				{/* <Route path="test" element={<Test />}></Route> */}
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	);

	const app = <>{routes} </>;

	return app;
}

export default RouteBuilder;
