import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
	const app = (
		<Container id="footer" fluid className="bg-dark text-white p-1 text-center">
			Copyright © 2022. All Rights Reserved. |
			<a href="#" className="px-3 text-decoration-none text-white">
				PROJECTTHAIHUB.COM
			</a>
		</Container>
	);
	return app;
}

export default Footer;
