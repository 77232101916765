import React from "react";
import { Card } from "react-bootstrap";
import SwapNow from "./SwapNow";
import ThaiNow from "./ThaiNow";
import ThaiNowNfts from "./ThaiNowNfts";

function Services() {
	const header1 = (
		<Card bg="Light" className="border-0">
			<Card.Header className="bg-white border-0 text-center fs-2 p-3 p-lg-5 fw-bold">
				OUR SERVICES
			</Card.Header>
		</Card>
	);

	const header2 = (
		<Card bg="Light" className="border-0 p-5 bg-light">
			<Card.Header className="bg-light border-0 text-center fs-2 tedkvn-swapnow-header p-3 p-lg-5 fw-bold ">
				NEW COMING APPLICATION
			</Card.Header>
		</Card>
	);

	const thaiNow = <ThaiNow />;
	const swapNow = <SwapNow />;
	const thaiNowNfts = <ThaiNowNfts></ThaiNowNfts>;

	const app = (
		<div id="services">
			{header1}
			{thaiNow}
			{header2}
			{swapNow}
			{thaiNowNfts}
		</div>
	);
	return app;
}

export default Services;
