import React from "react";
import {
	ButtonGroup,
	Col,
	Container,
	Image,
	Row,
	Button,
} from "react-bootstrap";

import thainowMobile from "../../../../Assests/images/thainow-mobile.png";
import thainowWeb from "../../../../Assests/images/web.png";
import thainowFace from "../../../../Assests/images/fb.png";
import thainowIg from "../../../../Assests/images/ig.png";
import thainowYtb from "../../../../Assests/images/youtube.png";

function ThaiNow() {
	const projectImage = (
		<Image
			src={thainowMobile}
			fluid={true}
			alt="ThaiNow Mobile Responsive image"
			className=" py-sm-5 my-lg-5 mx-lg-4 px-sm-4 tedkvn-center mw-100"
		/>
	);

	const titleNum = (
		<div className="fw-bold text-center tedkvn-thainow-titleNum text-decoration-underline">
			01
		</div>
	);
	const title = (
		<div className="fw-bold text-truncate mx-4 tedkvn-thainow-title">
			THAINOW
		</div>
	);

	const titleSection = (
		<Row className="tedkvn-center">
			<Col xs={12} lg={3}>
				{titleNum}
			</Col>
			<Col xs={12} lg={9}>
				{title}
			</Col>
		</Row>
	);

	const description = (
		<Row className="text-center text-lg-end float-end tedkvn-thainow-desc my-xl-5 py-5">
			<Col>
				ThaiNow is a platform aiming to support and promote the Thai community,
				Thai business, and togetherness as Thais in the United States.
			</Col>
		</Row>
	);

	const socialTitle = (
		<div className="text-center text-lg-end tedkvn-thainow-socialTitle">
			OUR SOCIAL MEDIA
		</div>
	);

	const socialIcons = [
		{
			src: thainowWeb,
			href: "https://thainowapp.com",
		},
		{
			src: thainowFace,
			href: "https://www.facebook.com/AppThainow",
		},
		{
			src: thainowYtb,
			href: "https://www.youtube.com/channel/UCHD-8nHZxoMyrTqhXJHZ5xQ",
		},
		{
			src: thainowIg,
			href: "https://www.instagram.com/thainow.app/",
		},
	];

	const socials = (
		<ButtonGroup
			aria-label="socials"
			className="my-3 my-lg-5 tedkvn-center float-lg-end "
		>
			{socialIcons.map((icon, index) => (
				<Button key={index} variant="link" href={icon.href} target="_blank">
					<Image
						fluid={true}
						src={icon.src}
						className="tedkvn-thainow-socialIcon-img"
					/>
				</Button>
			))}
		</ButtonGroup>
	);

	const socialSection = (
		<Row>
			<Col xs={12}>{socialTitle}</Col>
			<Col xs={{ span: 6, offset: 3 }} lg={9}>
				{socials}
			</Col>
		</Row>
	);

	const contentSection = (
		<Container className="my-2 py-2 ">
			{titleSection}
			{description}
			<div className="d-none d-lg-block">{socialSection}</div>
		</Container>
	);

	const app = (
		<Row id="thainow" className="bg-dark mt-5 pt-5 text-white mx-0">
			<Col xs={{ span: 12, order: 2 }} lg={{ span: 4, order: 1 }}>
				{projectImage}
			</Col>
			<Col xs={{ span: 12, order: 1 }} lg={{ span: 8, order: 2 }}>
				{contentSection}
			</Col>
			<Col xs={{ span: 12, order: "last" }} className="d-lg-none mt-5">
				{socialSection}
			</Col>
		</Row>
	);

	return app;
}

export default ThaiNow;
