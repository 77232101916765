import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import bottomBg from "../../../../Assests/images/bottom-bg.png";

function Contact() {
	const title = (
		<div className="fs-3 py-5">Contact us for news, tip, and more...</div>
	);
	const company = (
		<div className="fs-3 fw-bold" style={{ letterSpacing: "0.085em" }}>
			PROJECT THAIHUB INC.
		</div>
	);

	const companyAddress = "Los Angeles, CA 90027";
	const companyAddressURL =
		"https://www.google.com/maps/place/Thai+Town,+Los+Angeles,+CA+90027";
	const address = (
		<div className="my-5">
			<a
				href={companyAddressURL}
				target="_blank"
				rel="noreferrer"
				className="text-decoration-none text-dark"
			>
				<FontAwesomeIcon icon={faLocationDot} className="px-2" />
				{companyAddress}
			</a>
		</div>
	);

	const companyEmail = "contact@projectthaihub.com";
	const email = (
		<div>
			<a
				href={`mailto:${companyEmail}`}
				className="text-decoration-none text-dark text-uppercase"
			>
				<FontAwesomeIcon icon={faEnvelope} className="px-2" /> {companyEmail}
			</a>
		</div>
	);

	const contactSection = (
		<Container fluid className="text-center my-5 mx-0">
			{title}
			{company}
			{address}
			{email}
		</Container>
	);

	const bottomBackground = (
		<Container fluid className="m-0 p-0">
			<Image src={bottomBg} fluid={true} className="p-0 m-0" />
		</Container>
	);

	const app = (
		<Container
			id="contactUs"
			fluid
			className="p-0 m-0"
			style={{ backgroundColor: "#F2F2F0" }}
		>
			<Row className="p-0">
				<Col xs={12} className="mx-0 p-0">
					{contactSection}
				</Col>
				<Col xs={12} className="text-center">
					{bottomBackground}
				</Col>
			</Row>
		</Container>
	);
	return app;
}

export default Contact;
