import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";

import swapnowLogo from "../../../../Assests/images/swapnow-logo.png";
import swapnowImg from "../../../../Assests/images/swapnow-image.png";

import swapnowgg from "../../../../Assests/images/google.png";
import swapnowappl from "../../../../Assests/images/apple.png";

import swapnowitem1 from "../../../../Assests/images/item1.png";
import swapnowitem2 from "../../../../Assests/images/item2.png";
import swapnowitem3 from "../../../../Assests/images/item3.png";

function SwapNow() {
	const titleNum = (
		<div className="fw-bold text-center text-lg-start tedkvn-swapnow-titleNum text-decoration-underline">
			02
		</div>
	);
	const description = (
		<Row className="tedkvn-swapnow-desc mb-5 my-lg-5">
			<Col>
				We create an environment to connect everyone by swapping each
				other&lsquo;s items. Everything exists by a reason, so we don&lsquo;t
				waste it, we “swap it”. with “NO MEDIUM EXCHANGE”, our headline is “we
				swap it, we don&lsquo;t trade” and we are constantly innovating and
				aiming for the best experience for our users.
			</Col>
		</Row>
	);
	const moreBtn = (
		<Button
			variant="primary"
			href="https://swapnow.app"
			target="_blank"
			className="px-5 rounded-pill"
			size="lg"
		>
			More
		</Button>
	);
	const contentSection = (
		<Container className="my-2 py-2 ">
			{titleNum}
			<div className="d-none d-lg-block">{description}</div>
			<div className="d-none d-lg-block mt-4 mt-sm-5 pt-5">{moreBtn}</div>
		</Container>
	);

	const brand = <div>SWAPNOW</div>;
	const logo = <Image src={swapnowLogo} fluid={true} alt="SwapNow logo" />;
	const brandLogoSection = (
		<Row className="">
			<Col xs={12} lg={4} className="tedkvn-center-right tedkvn-swapnow-logo">
				{logo}
			</Col>
			<Col xs={12} lg={8} className="tedkvn-center-left tedkvn-swapnow-brand">
				{brand}
			</Col>
		</Row>
	);

	const projectImage = (
		<Image
			src={swapnowImg}
			fluid={true}
			alt="ThaiNow Mobile Responsive image"
			className="py-5 mw-100"
		/>
	);

	const projectImageSection = (
		<Container className="my-lg-4 py-lg-5 ">
			{brandLogoSection} {projectImage}
		</Container>
	);

	const platformItems = [
		{
			src: swapnowgg,
			href: "https://play.google.com/store/apps/details?id=com.pbm.swapnow&hl=en_US&gl=US",
		},
		{
			src: swapnowappl,
			href: "https://apps.apple.com/us/app/swapnow/id1613040762",
		},
	];
	const platformSection = (
		<div className="border-end p-lg-5 text-start">
			<Row className="tedkvn-swapnow-platform-header ">
				<Col>SwapNow Team</Col>
			</Row>
			<Row>
				<Col>
					<Button
						variant="link"
						className="mx-0 px-0 my-4 fs-5 text-decoration-none"
					>
						https://swapnow.app
					</Button>
				</Col>
			</Row>
			<Row>
				{platformItems.map((item, index) => (
					<Col key={index}>
						<Button
							variant="link"
							href={item.href}
							target="_blank"
							className="mx-0 px-0"
							size="lg"
						>
							<Image fluid={true} src={item.src} className="" />
						</Button>
					</Col>
				))}
			</Row>
		</div>
	);

	const itemList = [
		{ src: swapnowitem1, href: "" },
		{ src: swapnowitem2, href: "" },
		{ src: swapnowitem3, href: "" },
	];

	const itemsSection = (
		<div className=" p-lg-5 text-start">
			<Row className="tedkvn-swapnow-item-header  ">
				<Col>WE “SWAP” WHAT WE NEED</Col>
			</Row>
			<Row className="my-5">
				{itemList.map((item, index) => (
					<Col key={index}>
						<Button
							variant="link"
							href={item.href}
							target="_blank"
							className="mx-0 px-0"
							size="lg"
						>
							<Image fluid={true} src={item.src} className="" />
						</Button>
					</Col>
				))}
			</Row>
		</div>
	);

	const footer = (
		<Card className="border-0 mx-0 mb-5">
			<Card.Header className="bg-white border-0 text-center fs-2 mb-0 pb-0 fw-bold mx-0 px-0 ">
				<Row className="mx-0">
					<Col
						xs={{ span: 12, order: 2 }}
						lg={{ span: 6, order: 1 }}
						className="my-3 my-lg-0 pt-lg-5 bg-light tedkvn-swapnow-platform"
					>
						{platformSection}
					</Col>
					<Col
						xs={{ span: 12, order: 1 }}
						lg={{ span: 6, order: 2 }}
						className="my-3 my-lg-0 pt-5 bg-light"
					>
						{itemsSection}
					</Col>
				</Row>
			</Card.Header>
		</Card>
	);

	const app = (
		<Row id="swapnow" className="pt-lg-5 mx-0">
			<Col xs={12} lg={5} xl={6}>
				{contentSection}
			</Col>
			<Col xs={12} lg={7} xl={6}>
				{projectImageSection}
			</Col>
			<Col xs={12} className="d-block d-lg-none">
				{description}
			</Col>
			<Col xs={12} className="d-lg-none tedkvn-center">
				{moreBtn}
			</Col>
			<Col xs={12} className="px-0">
				{footer}
			</Col>
		</Row>
	);

	return app;
}

export default SwapNow;
