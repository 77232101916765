import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faFolderTree,
} from "@fortawesome/free-solid-svg-icons";

import genwar from "../../../../Assests/images/genwar.jpg";
import humanBeing from "../../../../Assests/images/human-being.jpg";
import mask from "../../../../Assests/images/mask.jpg";
import chicken from "../../../../Assests/images/chicken.png";

function ThaiNowNfts() {
	const titleNum = (
		<div className="fw-bold text-center tedkvn-thainowNfts-titleNum text-decoration-underline">
			03
		</div>
	);
	const title = (
		<div className="fw-bold text-truncate mx-5 tedkvn-thainowNfts-title">
			NFTs
		</div>
	);
	const titleSection = (
		<Row className="tedkvn-center float-lg-end mx-xl-5 my-sm-5 mt-5">
			<Col xs={12} lg={4}>
				{titleNum}
			</Col>
			<Col xs={12} lg={8}>
				{title}
			</Col>
		</Row>
	);

	const description = (
		<Container className="text-center">
			<p className="tedkvn-thainowNfts-desc-title">
				<strong> WHAT&lsquo;S NEW OF OUR NFT COMMUNITY?</strong>
			</p>

			<p className="p-5 fs-5">
				ThaiNowNFTs is a foundation of Thai Artists who discover, create, and
				sell outstanding NFTs
			</p>
		</Container>
	);

	const nftItems = [
		{
			cover: genwar,
			href: "https://opensea.io/collection/gen-war",
			title: "GENWAR",
			author: "ThaiNowNFTs",
			description: "A long time ago, in a galaxy  ......",
			totalItems: 3,
			date: "March, 2022",
		},
		{
			cover: humanBeing,
			href: "https://opensea.io/collection/human-being-7",
			title: "HUMAN BEING",
			author: "ThaiNowNFTs",
			description: "# If The world is *as 100 people as*, .......",
			totalItems: 100,
			date: "March, 2022",
		},
		{
			cover: mask,
			href: "https://opensea.io/collection/under-the-mask-s",
			title: "UNDER THE MASK(S)",
			author: "ThaiNowNFTs",
			description: "Are we talking about the real “masks”? Or ......",
			totalItems: 100,
			date: "March, 2022",
		},
		{
			cover: chicken,
			href: "https://opensea.io/collection/we-are-all-chicken",
			title: "WE ARE ALL CHICKEN",
			author: "ThaiNowNFTs",
			description: "100 Most interesting jobs in 2021...",
			totalItems: 100,
			date: "March, 2022",
		},
	];

	const NFTSection = (
		<Row className="text-dark  ">
			{nftItems.map((item, idx) => (
				<Col xs={12} sm={6} xxl={3} key={idx} className="my-5">
					<Card className="border-0 p-0 m-0">
						<a href={item.href} target="_blank" rel="noreferrer">
							<Card.Img src={item.cover} alt="Card image" />
						</a>
						<Card.Body>
							<Row className="text-muted pb-3">
								<Col>
									<div className="float-start">
										<FontAwesomeIcon icon={faCalendarDays} /> {item.date}
									</div>
									<div className="float-end">
										<FontAwesomeIcon icon={faFolderTree} className="px-1" />{" "}
										<span className="text-primary"> {item.totalItems}</span>{" "}
										Items
									</div>
								</Col>
							</Row>
							<Card.Title className="fw-bold">{item.title}</Card.Title>
							<Card.Text className="text-truncate">
								<small>
									Created By{" "}
									<a
										href="https://opensea.io/ThainowNFTs"
										target="_blank"
										rel="noreferrer"
										className="text-decoration-none d-inline-lbock"
									>
										{item.author}
									</a>
								</small>
							</Card.Text>
							<Card.Text className="text-truncate">
								{item.description}
							</Card.Text>
							<Button
								variant="link"
								className="text-decoration-none d-inlinelbock float-end"
								href={item.href}
								target="_blank"
							>
								Read More &gt;&gt;
							</Button>
						</Card.Body>
					</Card>
				</Col>
			))}
		</Row>
	);

	const moreBtn = (
		<Button
			variant="primary"
			href="https://opensea.io/ThainowNFTs"
			target="_blank"
			className="px-5 rounded-pill"
			size="lg"
		>
			More
		</Button>
	);

	const app = (
		<Container id="thainowNfts" fluid className="bg-dark text-white">
			<Row>
				<Col>{titleSection}</Col>
			</Row>
			<Row>
				<Col>{description}</Col>
			</Row>
			<Row>
				<Col>{NFTSection}</Col>
			</Row>
			<Row>
				<Col className="tedkvn-center my-5">{moreBtn}</Col>
			</Row>
		</Container>
	);
	return app;
}

export default ThaiNowNfts;
