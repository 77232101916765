import React from "react";
import "./privacyPolicy.css";

function PrivacyPolicy() {
  const app = (
    <div className="c6">
      <p className="c16">
        <span className="c8 c25">
          PROJECT THAIHUB a.k.a. &quot;SWAPNOW&quot;
        </span>
      </p>
      <p className="c16">
        <span className="c8 c12">PRIVACY POLICY</span>
      </p>
      <p className="c5 c9">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">
          Project Thaihub aka SWAPNOW (hereinafter &quot;we&quot;,
          &quot;us&quot;, &quot;our&quot;) is committed to maintaining privacy
          protections for its users (hereinafter &quot;you&quot;,
          &quot;user&quot; and/or &quot;users&quot;). Our Privacy Policy
          (hereinafter &quot;Privacy Policy&quot; and/or &quot;Policy&quot;) is
          designed to help you understand how we collect, use, communicate,
          disclose, make use of your personal information and protect the data
          and information you provide to us when you use our service by
          accessing either our online website or via our mobile application.
          &quot;Service&quot; as used in this Policy shall refer to our services
          accessed either through our online website and/or through our mobile
          applications. &nbsp;
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          Your privacy is essential to us. We reserve the right to change this
          policy at any given time, of which you will be promptly updated. If
          you want to make sure that you are up to date with the latest changes,
          we advise you to frequently visit this page.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          By accessing and using our Service, you accept our Policy and Terms of
          Use (which can be found at{" "}
        </span>
        <span className="c3 c2">
          <a
            className="c17"
            href="https://projectthaihub.com/swapnow/term-agreement"
          >
            Term Agreement
          </a>
        </span>
        <span className="c2">&nbsp;and </span>
        <span className="c3 c2">
          <a
            className="c17"
            href="https://projectthaihub.com/swapnow/privacy-policy"
          >
            Privacy Policy
          </a>
        </span>
        <span className="c2">
          ), and you expressly consent to our collection, storage, use, and
          disclosure of your personal information as described in this Policy.
        </span>
      </p>
      <p className="c5">
        <span className="c8">What User Data We Collect</span>
      </p>
      <p className="c5">
        <span className="c0">
          When you use our service, we may collect &quot;Non-Personal
          Information&quot; and &quot;Personal Information.&quot;
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          Non-Personal Information includes information that cannot be used to
          personally identify you, which includes but is not limited to the
          following:
        </span>
      </p>
      <ul className="c1 lst-kix_list_1-0 start">
        <li className="c15 li-bullet-0">
          <span className="c0">Anonymous usage data</span>
        </li>
        <li className="c14 li-bullet-0">
          <span className="c0">General demographic information</span>
        </li>
        <li className="c14 li-bullet-0">
          <span className="c0">Referring/exit pages and URLs </span>
        </li>
        <li className="c14 li-bullet-0">
          <span className="c0">Platform types</span>
        </li>
        <li className="c15 li-bullet-0">
          <span className="c0">
            Preferences you submit and those that are generated based on the
            date you submit and the number of clicks.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c0">
          Personal Information includes information that is personal to you,
          which you submit to us through the registration process and at other
          times for usage of our service. This personal information includes but
          is not limited to the following:
        </span>
      </p>
      <ul className="c1 lst-kix_21qkrhrguf83-0 start">
        <li className="c14 c18 li-bullet-0">
          <span className="c0">
            Your contact information, such as your phone number, address, and
            email
          </span>
        </li>
        <li className="c14 c18 li-bullet-0">
          <span className="c0">
            A photograph of you, when and where appropriate
          </span>
        </li>
        <li className="c14 c18 li-bullet-0">
          <span className="c0">
            Other information such as interests and preferences.
          </span>
        </li>
        <li className="c18 c20 li-bullet-0">
          <span className="c0">
            Data profile regarding your online behavior on our website.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c0">
          This information also includes information in your profile or
          information you provide to us via an email, text, telephone call, or
          by some other similar means. We also collect information about how you
          use our Services, such as the types of content you view or engage with
          or the frequency and duration of your activities. Your telephone calls
          and other communications with us are usually recorded or monitored for
          quality control, training, or for similar purposes. We also collect
          information about any purchases and/or transactions. This includes
          your payment information, such as your credit or debit card number and
          other card information, and other account and authentication
          information, as well as billing, shipping, and contact details.
        </span>
      </p>
      <p className="c5">
        <span className="c8">Why We Collect Your Data</span>
      </p>
      <p className="c5">
        <span className="c0">
          Before or at the time we collect your personal information, we will
          identify the purposes for which information is being collected. We
          will collect and use personal information solely with the objective of
          fulfilling those purposes specified by us and for other compatible
          purposes unless we obtain your prior consent or as required by law. We
          will collect personal information by lawful and fair means and, where
          appropriate, with the knowledge or consent of the individual
          concerned.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          In general, we are collecting your data for several reasons:
        </span>
      </p>
      <ul className="c1 lst-kix_woawpvr0ntpk-0 start">
        <li className="c14 c18 li-bullet-0">
          <span className="c0">
            To help us better communicate with you and provide you with
            technical support
          </span>
        </li>
        <li className="c14 c18 li-bullet-0">
          <span className="c0">
            To understand your needs and to improve our services and products to
            meet those needs.
          </span>
        </li>
        <li className="c14 c18 li-bullet-0">
          <span className="c0">
            To send you promotional emails containing offers and/or information
            we think you will find interesting.
          </span>
        </li>
        <li className="c14 c18 li-bullet-0">
          <span className="c0">
            To possibly display interest-based ads for features, products, and
            services that might be of interest to you. However, we will not use
            information that personally identifies you to display interest-based
            ads.
          </span>
        </li>
        <li className="c20 c18 li-bullet-0">
          <span className="c0">
            To customize our website according to your online behavior and
            personal preferences.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c8">How we collect your Information </span>
      </p>
      <ol className="c1 lst-kix_kxvnuqufw0ov-0 start" start="1">
        <li className="c13 li-bullet-0">
          <span className="c8">Via Technology</span>
        </li>
      </ol>
      <p className="c7">
        <span className="c0">
          To activate and use our Service, you do not need to submit any
          Personal Information other than your phone number, email, or zip code.
          To use our Service thereafter, you do need to submit further Personal
          Information, which may possibly include email, education, address,
          skills, work experience, etc. However, in an effort to improve the
          quality of the Service, we track information provided to us by your
          browser or by our software application when you view or use our
          Services, such as the type of browser you use, the time and date of
          access, and other information that does not personally identify you.
        </span>
      </p>
      <p className="c7">
        <span className="c0">
          When you use our Service, our servers automatically log certain
          information about your use of our Services, such as your IP address,
          your browser type, operating system, browser language, and service
          provider. We also collect information about your activities related to
          our Services, such as the pages you visit or the ads or links you
          click on. We use this information to provide and adapt our service to
          take into account the technical capabilities of our users as well as
          identify issues with the website and users&#39; experience of it.
        </span>
      </p>
      <p className="c7">
        <span className="c2">
          We automatically collect and store certain types of information about
          your use of SWAPNOW services, including information about your
          interaction with content and services available through SWAPNOW
          services. Like many websites, we use &quot;cookies&quot; and other
          unique identifiers to enable our systems to recognize your browser or
          device and provide and improve SWAPNOW Services. For more information
          about this, please refer to section{" "}
        </span>
        <span className="c8">Our Cookie Policy</span>
        <span className="c0">. </span>
      </p>
      <p className="c5">
        <span className="c0">
          Session Replay: When you visit our website, we may also record
          information about your browsing session on the site, including which
          pages you visited and any forms on the website that you filled in. We
          review this information to identify any technical issues in the
          operation of our sites and to improve the user interface on our sites.
          If we use a third party to help us with this service, this information
          will be passed to that third party for this purpose.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          Mobile Analytics: When you download and use our application, we may
          use mobile analytics software to help us better understand the
          functionality of our application on your mobile device. Our analytics
          may record how often you use the application, how you use the
          application, aggregated usage, performance data, and where you
          downloaded the application from.
        </span>
      </p>
      <ol className="c1 lst-kix_kxvnuqufw0ov-0" start="2">
        <li className="c23 li-bullet-0">
          <span className="c8">
            Via the Information you provide us by registering for an account
          </span>
        </li>
      </ol>
      <p className="c5">
        <span className="c0">
          In addition to the information provided automatically by your browser
          when you visit our website, to become a registered user or subscriber,
          you will need to create a personal profile. You can create a profile
          by registering with the Service entering your phone number, email,
          address, and creating a username and a password. By registering, you
          are explicitly authorizing us to collect, store, and use your email
          address in accordance with this Policy.
        </span>
      </p>
      <ol className="c1 lst-kix_kxvnuqufw0ov-0" start="3">
        <li className="c23 li-bullet-0">
          <span className="c8">Children&#39;s Privacy</span>
        </li>
      </ol>
      <p className="c5">
        <span className="c2">
          The Site and the Service are not directed or intended for use by
          anyone under the age of 18. The Site does not allow anyone under the
          age of 18 to sign up for the Services. We do not knowingly collect
          personal information from children under the age of 18 without the
          prior consent and expressed authorization of the child&#39;s parent or
          guardian. In the event that we learn that we have gathered personal
          information from anyone under the age of 18 without the consent of a
          parent or guardian, we will delete that information as soon as
          reasonably possible. If you believe we have collected such
          information, please contact us at{" "}
        </span>
        <span className="c3 c2">
          <a className="c17" href="mailto:contact@projectthaihub.com">
            Project ThaiHub Support
          </a>
        </span>
      </p>
      <p className="c5">
        <span className="c8">Safeguarding and Securing the Data</span>
      </p>
      <p className="c5">
        <span className="c0">
          We are committed to securing your data and keeping it confidential. We
          have done all our power to prevent data theft, unauthorized access,
          and disclosure by implementing the latest technologies and software,
          which help us safeguard all the information we collect online. We will
          make readily available to users information about our policies and
          practices relating to the management of personal information. We are
          committed to conducting our business in accordance with these
          principles to ensure that the confidentiality of personal information
          is protected and maintained. If you post services related to your
          business, we will ask you for information about the service and your
          business information. This includes information about your service and
          details about your business.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          Your account password protects your account, and we urge you to take
          steps to keep your personal information safe by not disclosing your
          password and by logging out of your account after each use. We further
          protect your information from potential security breaches by
          implementing technological security measures, including encryption,
          firewalls, and secure socket layer technology. However, no security
          system is impenetrable, and we cannot guarantee the security of our
          system at all times. The measures of security that we have in place do
          not guarantee that your information will not be accessed, disclosed,
          altered, or destroyed by breach of such firewalls and secure server
          software. By using our Service, you acknowledge that you understand
          and agree to assume these risks.
        </span>
      </p>
      <p className="c5">
        <span className="c21 c2">Identity Verification</span>
        <span className="c0">
          : We may use identity verification services provided by a third-party
          provider to verify your identity documents, confirm your identity and
          protect against fraud and abuse. When you create an account, you may
          be asked to submit valid identification proofs, along with a
          self-image. Our third-party provider may use a combination of machine
          learning tools and optical scans to authenticate your identity
          documents and may use facial recognition technology to produce a
          unique biometric identifier based on facial geometry that can be used
          to compare your self-image to the identity document you provided to
          determine the likelihood that the images are a &quot;match.&quot; We
          do not receive the biometric identifier generated from the images. Our
          third-party provider generated and held until we are informed that the
          biometric identifier is no longer needed for the purposes described in
          this paragraph, which will occur no later than three years after your
          last interaction with us. For identity verification and security
          purposes, we will have access to the self-image and will receive the
          information extracted from the identity scan of the ID document as
          well as the results of the identity verification process. We may use
          this process and associated information to verify your identity,
          authenticate your identity documents, and protect against fraud and
          misrepresentation. We do not use, disclose or retain your biometric
          information for any other commercial purpose. Our third-party provider
          processes this information on our behalf strictly in accordance with
          our contractual agreements.
        </span>
      </p>
      <p className="c5">
        <span className="c8 c10">
          Your Rights Regarding the Use of Your Personal Information
        </span>
      </p>
      <p className="c5">
        <span className="c2 c11 c10">
          You have the right at any time to prevent us from contacting you for
          marketing purposes. When we send a promotional communication to a
          user, the user can{" "}
        </span>
        <span className="c2 c11">opt-out</span>
        <span className="c2 c11 c10">
          &nbsp;of further promotional communications by following the
          unsubscribe instructions provided in each promotional email. You can
          also indicate that you do not wish to receive marketing communications
          from us in the Settings on the Site. Please note that notwithstanding
          the promotional preferences you indicate by either unsubscribing or
          opting out of the Site, we may continue to send you administrative
          emails
        </span>
        <span className="c2 c11">,</span>
        <span className="c4 c2">
          &nbsp;including, for example, periodic updates to our Policies.
        </span>
      </p>
      <p className="c5">
        <span className="c8">Our Cookie Policy</span>
      </p>
      <p className="c5">
        <span className="c2">
          Once you agree to allow our website to use cookies, you also agree to
          use the data it collects regarding your online behavior (analyze web
          traffic, web pages you spend the most time on, and websites you
          visit).
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          The data we collect by using cookies is used to customize our website
          to your needs. After we use the data for statistical analysis, the
          data is completely removed from our systems.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          Please note that cookies don&#39;t allow us to gain control of your
          computer in any way. They are strictly used to monitor which pages you
          find useful and which you do not so that we can provide a better
          experience for you.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          If you want to disable cookies, you can do it by accessing the
          settings of your internet browser.
        </span>
      </p>
      <p className="c5">
        <span className="c8">Usage and Sharing of Information </span>
      </p>
      <p className="c5">
        <span className="c21 c2">Personal Information</span>
        <span className="c0">
          : In general, the Personal Information you provide is used to help us
          communicate with you and provide you with a better service.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          Except as otherwise stated in this Policy, we do not sell, trade,
          rent, or otherwise share your Personal Information for marketing
          purposes with third parties without your prior consent. We do share
          Personal Information with vendors who are performing services for us,
          such as for our email communications who are provided access to the
          user&#39;s email address for purposes of sending emails from us.
          &nbsp;Those vendors use your Personal Information only at our
          direction and in accordance with our Policy.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          We may share Personal Information with outside parties if we have a
          good-faith belief that access, use, preservation, or disclosure of the
          information is reasonably necessary to meet any applicable legal
          process or enforceable governmental request; to enforce applicable
          Terms of Service, including but not limited to, the investigation of
          potential violations; address fraud, security or technical concerns;
          or to protect against harm to the rights, property, or safety of our
          users or the public as required or permitted by law.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c21">Non-Personal Information</span>
        <span className="c0">
          : In general, we use Non-Personal Information to help us improve the
          Service and customize the user experience. We also aggregate
          Non-Personal Information to track trends and analyze use patterns on
          the Site. This Policy does not limit in any way our use or disclosure
          of Non-Personal Information, and we reserve the right to use and
          disclose such Non-Personal Information to our partners, advertisers,
          and other third parties at our discretion.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          In the event we undergo a business transaction such as a merger,
          acquisition by another company, or sale of all or a portion of our
          assets, your information may be among the assets transferred. You
          acknowledge and consent that such transfers may occur and are
          permitted by this Policy and that any acquirer of our assets may
          continue to process your information as set forth in this Policy. If
          our information practices change at any time in the future, we will
          post the changes so that you may opt-out of the new information
          practices. We suggest that you check the Site periodically if you are
          concerned about how your information is used.
        </span>
      </p>
      <p className="c5">
        <span className="c8">Links to Other Websites</span>
      </p>
      <p className="c5">
        <span className="c2">
          As part of our Service, we may provide links to or compatibility with
          other websites or applications. However, we are not responsible for
          the privacy practices employed, information, or content presented by
          those other websites or applications. Therefore, this Policy does not
          apply to your use of a third-party website or application accessed by
          selecting a link on our Site or our Service. In addition, if you click
          on these links, we are not held responsible for your data and privacy
          protection. These third-party advertising partners may collect
          information about you when you interact with their content,
          advertising, and services. Visiting those websites is not governed by
          this Policy agreement. We encourage our users to read the privacy
          statements of other websites and applications before proceeding to use
          them.
        </span>
      </p>
      <p className="c5">
        <span className="c8">
          Restricting the Collection of your Personal Data
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          At some point, you might wish to restrict the use and collection of
          your personal data. You can achieve this by doing the following:
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          When you are filling out the forms on the website or applications,
          make sure to check if there is a box that you can leave unchecked, if
          you don&#39;t want to disclose your personal information.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          If you have already agreed to share your information with us, feel
          free to contact us via email, and we will be more than happy to change
          this for you.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          Project ThaiHub Inc. will not lease, sell or distribute your personal
          information to any third parties unless we have your permission. We
          might do so if the law forces us. Your personal information will be
          used when we need to send you promotional materials if you agree to
          this privacy policy.
        </span>
      </p>
      <p className="c5">
        <span className="c8">Contact Us</span>
      </p>
      <p className="c18 c19">
        <span className="c2">
          If you have any questions regarding this Policy or our practices in
          general, please contact us by sending us an email to{" "}
        </span>
        <span className="c2 c3">
          <a className="c17" href="mailto:contact@projectthaihub.com">
            Project ThaiHub Support
          </a>
        </span>
        <span className="c0">.</span>
      </p>
      <p className="c19 c18 c9">
        <span className="c0"></span>
      </p>
      <p className="c19 c18" id="h.gjdgxs">
        <span className="c2">
          Last Update: This Privacy Policy was last updated on{" "}
        </span>
        <span className="c2">Jan 28, 2022</span>
      </p>
    </div>
  );

  return <div className="pristyle">{app}</div>;
}

export default PrivacyPolicy;
