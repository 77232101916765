import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Body from "./Body/Body";
import Footer from "./Footer";
import Header from "./Header";

function Home() {
	const header = <Header />;
	const body = <Body />;
	const footer = <Footer />;

	const app = (
		// <div> test</div>
		<Container id="home">
			<Row xs={12}>
				<Col xs={12}>{header}</Col>
				<Col xs={12} className="mt-4">
					{body}
				</Col>
				<Col xs={12}>{footer}</Col>
			</Row>
		</Container>
	);

	return app;
}

export default Home;
