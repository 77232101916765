import React from "react";
import {
	Button,
	Card,
	Col,
	Nav,
	OverlayTrigger,
	Row,
	Tooltip,
} from "react-bootstrap";

function Intro() {
	const intro = (
		<Card className="border-0 pt-5 bg-light mt-4">
			<Card.Body className="px-4 mx-sm-4 px-lg-5 mx-lg-5 ">
				<Card.Subtitle
					className="mb-2 text-truncate"
					style={{ color: "#315EFF" }}
				>
					<OverlayTrigger
						placement="top"
						overlay={
							<Tooltip id={`tooltip-introTop}`}>
								Perfect solutions of Community Creativity and Services!
							</Tooltip>
						}
					>
						<strong>
							Perfect solutions of Community Creativity and Services!
						</strong>
					</OverlayTrigger>
				</Card.Subtitle>

				<Card.Title className="pt-5 pb-4 pb-sm-5 fs-1 fw-bold ">
					<strong> PROJECT THAIHUB INC. </strong>
				</Card.Title>

				<Card.Text className="text-muted fs-4" style={{ maxWidth: "50rem" }}>
					Creating a great work environment for Developers, Designers, Artists,
					etc., to facilitate users&apos; experience.
				</Card.Text>

				<Card.Link
					as={Button}
					href="#services"
					className="mt-4 mt-sm-5 rounded-pill"
					style={{ width: "10rem" }}
				>
					More
				</Card.Link>
			</Card.Body>
		</Card>
	);

	// linear-gradient(180deg, #FFFFFF 50.05%, #000000 100%);

	const introNavItems = [
		{
			href: "#thainow",
			title: "ThaiNow",
		},
		{
			href: "#swapnow",
			title: "SwapNow",
		},
		{
			href: "#thainowNfts",
			title: "ThaiNowNFTs",
		},
	];

	const introNav = (
		<Card
			className="border-0"
			style={{
				background:
					"linear-gradient(180deg, rgba(248,249,250,1) 50%, #FFFFFF 60%)",
			}}
		>
			<Card.Body className="mt-5 pb-5 ">
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<Nav
							justify
							variant="pill"
							className="bg-white border rounded-pill "
						>
							{introNavItems.map((nav, index) => (
								<Nav.Item key={index}>
									<Nav.Link
										id={`introNavItem-${nav.title}`}
										href={nav.href}
										className={`${
											index < introNavItems.length - 1 ? "border-end" : ""
										} my-3 fw-bolder text-truncat tedkvn-center`}
										style={{
											height: "3rem",
											color: "#315EFF",
										}}
									>
										{nav.title}
									</Nav.Link>
								</Nav.Item>
							))}
						</Nav>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
	const app = (
		<>
			{intro}
			{introNav}
		</>
	);

	return app;
}

export default Intro;
