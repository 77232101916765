import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

function Header() {
	const navItems = [
		{
			title: "HOME",
			href: "#home",
		},
		{
			title: "SERVICES",
			href: "#services",
		},
		{
			title: "CONTACT US",
			href: "#contactUs",
		},
	];

	const app = (
		<Navbar
			bg="white"
			variant="light"
			expand="md"
			className="py-4"
			fixed="top"
			collapseOnSelect
		>
			<Container>
				<Navbar.Brand href="#">
					<img
						alt=""
						src="/logo.png"
						width="30"
						height="30"
						className="d-inline-block align-top img-fluid"
					/>{" "}
					<strong>Project ThaiHub Inc.</strong>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbar-nav" />
				<Navbar.Collapse id="navbar-nav">
					<Nav className="ms-auto pt-2">
						{navItems.map((nav, index) => (
							<Nav.Link key={index} href={nav.href} className="mx-md-2 mx-lg-5">
								{nav.title}
							</Nav.Link>
						))}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);

	return app;
}

export default Header;
