import React from "react";
import "./termAgreement.css";

function TermAgreement() {
  const app = (
    <div className="c9">
      <p className="c1">
        <span className="c2 c13">
          PROJECT THAIHUB a.k.a. &quot;SWAPNOW&quot;
        </span>
      </p>
      <p className="c1" id="h.gjdgxs">
        <span className="c2 c8">TERMS OF USE</span>
      </p>
      <p className="c5">
        <span className="c0">&nbsp;</span>
      </p>
      <p className="c5">
        <span className="c7">
          Welcome to the SWAPNOW Terms and Conditions Agreement (hereinafter
          &ldquo;Terms&rdquo; or these &ldquo;Service Standard Terms and
          Conditions&rdquo; or &ldquo;Agreement&rdquo;). The Terms contained
          herein shall govern your use of this website, and/or application,
          including all pages within this website (collectively referred to
          herein below as this &ldquo;Website&rdquo;) and the application.
          &nbsp;For purposes of this Agreement, &ldquo;Site&rdquo; refers to the
          Company&rsquo;s website or online mobile applications
        </span>
        <span className="c0">
          . &nbsp;&ldquo;Service&rdquo; refers to the Company&rsquo;s services
          accessed via the online mobile applications, which provide people a
          better way to swap their unwanted people to something of fair value.
          The swap action would allow users to easily exchange their available
          or unwanted items for other items from other users. The terms
          &ldquo;we,&rdquo; &ldquo;us,&rdquo; and &ldquo;our&rdquo; refer to the
          company SWAPNOW. &ldquo;You&rdquo; refers to you, as a user of our
          Site or our Service.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c7">
          The following Terms of Use apply when you view or use the Service via
          our website located at{" "}
        </span>
        <span className="c11 c7">
          <a
            className="c6"
            href="https://projectthaihub.com/swapnow/term-agreement"
          >
            Term and Agreement
          </a>
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c7">
          Please review the following terms carefully. By accessing or using the
          Service, you expressly agree to these Terms and Conditions of Use, all
          applicable laws, and regulations, and agree that you are responsible
          for compliance with any applicable local laws. The materials contained
          in our applications are protected by applicable copyright and
          trademark law.{" "}
        </span>
        <span className="c2">
          If you do not agree to be bound by these Terms of Use in their
          entirety, you may not access or use the Service
        </span>
        <span className="c0">. &nbsp;</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">PRIVACY POLICY</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c7">
          The Company respects the privacy of its Service users. Please refer to
          the Company&rsquo;s Privacy Policy (found here:{" "}
        </span>
        <span className="c11 c7">
          <a
            className="c6"
            href="https://projectthaihub.com/swapnow/privacy-policy"
          >
            Privacy Policy
          </a>
        </span>
        <span className="c0">
          ) which explains how we collect, use, and disclose information that
          pertains to your privacy. When you access or use the Service, you
          signify your agreement to the Privacy Policy as well as these Terms of
          Use.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">
          REGISTRATION; RULES FOR USER CONDUCT AND USE OF THE SERVICE
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          You need to be at least 18 years old to register for and use the
          Service.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          If you are a user who signs up for the Service, you will create a
          personalized account that includes a unique username and a password to
          access the Service and to receive messages from the Company. You agree
          to notify us immediately of any unauthorized use of your username,
          password, and/or account. The Company will not be responsible for any
          liabilities, losses, or damages arising out of the unauthorized use of
          your username, password, and/or account.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">USE RESTRICTIONS</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          Your permission to use the Site is conditioned upon the following use,
          posting, and conduct restrictions:
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          You agree that you will not under any circumstances:
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <ol className="c10 lst-kix_list_1-0 start" start="1">
        <li className="c4 li-bullet-0">
          <span className="c0">
            Publish or distribute any part or parts of the Site or the Service
            without our explicit written permission (we grant the operators of
            public search engines permission to use spiders to copy materials
            from the site for the sole purpose of creating publicly available
            searchable indices but retain the right to revoke this permission at
            any time on a general or specific basis);
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Selling, sublicensing, and/or otherwise commercializing any
            Website/Application material;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Use the Service for any unlawful purpose or for the promotion of
            illegal activities;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Harass, abuse, or in any way harm another person or group, or
            attempt to do so;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Use another user&rsquo;s account without permission;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Intentionally allow another user to access your account;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Provide false or inaccurate information when registering an account;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Interfere or attempt to interfere with the proper functioning of the
            Service;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Make any automated use of the Site, the Service, or the related
            systems, or take any action that we deem to impose or to potentially
            impose an unreasonable or disproportionately large load on our
            servers or network infrastructure;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Bypass any robot exclusion headers or other measures we take to
            restrict access to the Service, or use any software, technology, or
            device to scrape, spider, or crawl the Service or harvest or
            manipulate data;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Circumvent, disable or otherwise interfere with any security-related
            features of the Service or features that prevent or restrict use or
            copying of content, or enforce limitations on use of the Service or
            the content accessible via the Service; or
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Publish or link to malicious content of any sort, including that
            intended to damage or disrupt another user&rsquo;s browser or
            computer.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website or the
            application, or while using our service.
          </span>
        </li>
      </ol>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">POSTING AND CONDUCT RESTRICTIONS</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          When you create your own personalized account, you are solely
          responsible for the User Content that you post, upload, link to, or
          otherwise make available via the Service. &ldquo;User Content&rdquo;
          shall mean any audio, video, text, images, ideas, beliefs, or other
          material you choose to display on this website or application. You
          agree that we are only acting as a passive conduit for your online
          distribution and publication of your User Content. The Company,
          however, reserves the right to remove any User Content from the
          Service at its sole discretion.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          We grant you permission to use and access the Service, subject to the
          following express conditions surrounding User Content. You agree that
          failure to adhere to any of these conditions constitutes a material
          breach of these Terms.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          By transmitting and submitting any User Content while using the
          Service, you agree as follows:
        </span>
      </p>
      <ul className="c10 lst-kix_kom9ed3fazbr-0 start">
        <li className="c4 li-bullet-0">
          <span className="c0">
            You are solely responsible for your account and the activity that
            occurs while signed in to or while using your account;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            You will not post information that is malicious, libelous, false, or
            inaccurate;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            You will not post any information that is abusive, threatening,
            obscene, defamatory, violation, libelous, or racially, sexually,
            religiously, or otherwise objectionable and offensive (for example
            politics and royal families);
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            You retain all ownership rights in your User Content but you are
            required to grant the following rights to the Site and to users of
            the Service as set forth more fully under the &ldquo;License
            Grant&rdquo; and &ldquo;Intellectual Property&rdquo; provisions
            below: When you upload or post User Content to the Site or the
            Service, you grant to the Site a worldwide, non-exclusive,
            royalty-free, transferable license to use, reproduce, distribute,
            prepare derivative works of, display, and perform that Content in
            connection with the provision of the Service; and you grant to each
            user of the Service, a worldwide, non-exclusive, royalty-free
            license to access your User Content through the Service, and to use,
            reproduce, distribute, prepare derivative works of, display and
            perform such Content to the extent permitted by the Service and
            under these Terms of Use;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            You will not submit content that is copyrighted or subject to third
            party proprietary rights, including privacy, publicity, trade
            secret, or others unless you are the owner of such rights or have
            the appropriate permission from their rightful owner to specifically
            submit such content; and
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            You hereby agree that we have the right to determine whether your
            User Content submissions are appropriate and comply with these Terms
            of Service, remove any and/or all your submissions, and terminate
            your account with or without prior notice.
          </span>
        </li>
      </ul>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          You understand and agree that any liability, loss, or damage that
          occurs as a result of the use of any User Content that you make
          available or access through your use of the Service is solely your
          responsibility. The Site is not responsible for any public display or
          misuse of your User Content. The Site does not, and cannot, pre-screen
          or monitor all User Content. However, at our discretion, we, or the
          technology we employ, may monitor and/or record your interactions with
          the Service or with other Users.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">ONLINE CONTENT DISCLAIMER</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          Opinions, advice, statements, offers, or other information or content
          made available through the Service, but not directly by the Site, are
          those of their respective authors, and should not necessarily be
          relied upon. Such authors are solely responsible for such content, and
          we are not responsible for any damages arising from their content.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          We do not guarantee the accuracy, completeness, or usefulness of any
          information on the Site or the Service nor do we adopt nor endorse,
          nor are we responsible for, the accuracy or reliability of any
          opinion, advice, or statement made by other parties. We take no
          responsibility and assume no liability for any User Content that you
          or any other user or third-party posts or sends via the Service. Under
          no circumstances will we be responsible for any loss or damage
          resulting from anyone&rsquo;s reliance on information or other content
          posted on the Service or transmitted to users. &nbsp;
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          Though we strive to enforce these Terms of Use, you may be exposed to
          User Content that is inaccurate or objectionable when you use or
          access the Site or the Service. We reserve the right, but have no
          obligation, to monitor the materials posted in the public areas of the
          Site or the Service or to limit or deny a user&rsquo;s access to the
          Service or take other appropriate action if a user violates these
          terms. No Warranties.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          Our service is provided &ldquo;as is&rdquo;, with all faults, and
          SWAPNOW makes no express or implied representations or warranties, of
          any kind to this Website or application.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c7">
          Terms of Use or engages in any activity that violates the rights of
          any person or entity or which we deem unlawful, offensive, abusive,
          harmful, or malicious. E-mails sent between you and other participants
          that are not readily accessible to the general public will be treated
          by us as private to the extent required by applicable law. Nothing
          contained on this Website shall be construed as providing consultation
          or advice to you. &nbsp;The Company shall have the right to remove any
          material that in its sole opinion violates or is alleged to violate,
          the law or this agreement or which might be offensive, or that might
          violate the rights, harm, or threaten the safety of users or others.
          &nbsp;Unauthorized use may result in criminal and/or civil prosecution
          under Federal, State, and local law. If you become aware of misuse of
          our Service or violation of these Terms of Use, please contact us at{" "}
        </span>
        <span className="c7 c11">
          <a className="c6" href="mailto:contact@projectthaihub.com">
            Project ThaiHub Support
          </a>
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">LINKS TO OTHER SITES AND/OR MATERIALS</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          As part of the Service, we may provide you with convenient links to a
          third-party website(s) (&ldquo;Third-Party Sites&rdquo;) as well as
          content or items belonging to or originating from third parties (the
          &ldquo;Third-Party Applications, Software or Content&rdquo;). These
          links are provided as a courtesy to Service subscribers. We have no
          control over Third-Party Sites or Third-Party Applications, Software
          or Content or the promotions, materials, information, goods or
          services available on these Third-Party Sites or Third-Party
          Applications, Software or Content. Such Third-Party Sites and
          Third-Party Applications, Software or Content are not investigated,
          monitored, or checked for accuracy, appropriateness, or completeness,
          and we are not responsible for any Third-Party Sites accessed through
          the Site or any Third-Party Applications, Software or Content posted
          on, available through or installed from the Site, including the
          content, accuracy, offensiveness, opinions, reliability, privacy
          practices or other policies of or contained in the Third-Party Sites
          or the Third-Party Applications, Software or Content. Inclusion of,
          linking to, or permitting the use or installation of any Third-Party
          Site or any Third-Party Applications, Software, or Content does not
          imply our approval or endorsement. If you decide to leave the Site and
          access the Third-Party Sites or to use or install any Third-Party
          Applications, Software, or Content, you do so at your own risk and you
          should be aware that our terms and policies, including these Terms of
          Use, no longer govern. You should review the applicable terms and
          policies, including privacy and data gathering practices, of any
          Third-Party Site to which you navigate from the Site or relating to
          any applications you use or install from the Third-Party Site.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">LICENSE GRANT</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          By posting any User Content via the Service, you expressly grant, and
          you represent and warrant that you have a right to grant, to the
          Company a royalty-free, sublicensable, transferable, perpetual,
          irrevocable, non-exclusive, worldwide license to use, reproduce,
          modify, publish, list information regarding, edit, translate,
          distribute, publicly perform, publicly display, and make derivative
          works of all such User Content and your name, voice, and/or likeness
          as contained in your User Content, if applicable, in whole or in part,
          and in any form, media or technology, whether now known or hereafter
          developed, for use in connection with the Service.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          This is the grant of a license, not a transfer of title, and under
          this license, you may not:
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          modify or copy the materials; use the materials for any commercial
          purpose, or for any public display (commercial or non-commercial);
          attempt to decompile or reverse engineer any software contained on
          SWAPNOW&#39;s website and mobile applications; remove any copyright or
          other proprietary notations from the materials; or transfer the
          materials to another person or &quot;mirror&quot; the materials on any
          other server. This license shall automatically terminate if you
          violate any of these restrictions and may be terminated by SWAPNOW at
          any time. Upon terminating your viewing of these materials or upon the
          termination of this license, you must destroy any downloaded materials
          in your possession whether in electronic or printed format.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">INTELLECTUAL PROPERTY</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          You acknowledge and agree that we and our licensors retain ownership
          of all intellectual property rights of any kind related to the
          Service, including applicable copyrights, trademarks, and other
          proprietary rights. You are granted a limited license only, subject to
          the restrictions provided in these Terms, for purposes of viewing the
          material contained on this Website. Other product and company names
          that are mentioned on the Service may be trademarks of their
          respective owners. We reserve all rights that are not expressly
          granted to you under these Terms of Use.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">EMAIL MAY NOT BE USED TO PROVIDE NOTICE</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          Communications made through the Service&rsquo;s email and messaging,
          or text messaging system will not constitute legal notice to the Site,
          the Service, or any of its officers, employees, agents, or
          representatives in any situation where legal notice is required by
          contract or any law or regulation.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">
          USER CONSENT TO RECEIVE COMMUNICATIONS IN ELECTRONIC FORM
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          For contractual purposes, you: (a) consent to receive communications
          from us in an electronic form via the email address you have
          submitted; and (b) agree that all Terms of Use, agreements, notices,
          disclosures, and other communications that we provide to you
          electronically satisfy any legal requirement that such communications
          would satisfy if it were in writing. The foregoing does not affect
          your non-waivable rights.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          We may also use your email address to send you other messages,
          including information about the Site or the Service and special
          offers. You may opt-out of such email by changing your account
          settings, using the &ldquo;Unsubscribe&rdquo; link in the message, or
          mail/email to the following address:
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">Customer Support</span>
      </p>
      <p className="c5">
        <span className="c7">Thai Town, Hollywood - </span>
        <span className="c11 c7">
          <a className="c6" href="mailto:contact@projectthaihub.com">
            Project ThaiHub Support
          </a>
        </span>
        <span className="c0">&nbsp;</span>
      </p>
      <p className="c5">
        <span className="c0">
          Opting out may prevent you from receiving messages regarding the Site,
          the Service, or special offers.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">WARRANTY DISCLAIMER</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          THE SERVICE IS PROVIDED &ldquo;AS IS,&rdquo; WITHOUT A WARRANTY OF ANY
          KIND. WITHOUT LIMITING THE FOREGOING, WE EXPRESSLY DISCLAIM ALL
          WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, REGARDING THE
          SERVICE INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY, AND
          NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY
          OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE SERVICE WILL BE
          UNINTERRUPTED OR ERROR-FREE. YOU ASSUME FULL RESPONSIBILITY AND RISK
          OF LOSS RESULTING FROM YOUR DOWNLOADING AND/OR USE OF FILES,
          INFORMATION, CONTENT, OR OTHER MATERIAL OBTAINED FROM THE SERVICE.
          SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF WARRANTY, SO
          THIS PROVISION MAY NOT APPLY TO YOU.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">
          LIMITATION OF LIABILITY / LIMITATION OF DAMAGES
        </span>
        <span className="c7">; </span>
        <span className="c2">RELEASE</span>
      </p>
      <p className="c5">
        <span className="c2">(LIMITATION OF LIABILITY)</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE SITE,
          THE SERVICE, ITS AFFILIATES, DIRECTORS, OR EMPLOYEES, OR ITS LICENSORS
          OR PARTNERS, BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, USE, &nbsp;OR
          DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR
          EXEMPLARY DAMAGES, HOWEVER ARISING, THAT RESULT FROM: (A) THE USE,
          DISCLOSURE, OR DISPLAY OF YOUR USER CONTENT; (B) YOUR USE OR INABILITY
          TO USE THE SERVICE; (C) THE SERVICE GENERALLY OR THE SOFTWARE OR
          SYSTEMS THAT MAKE THE SERVICE AVAILABLE; OR (D) ANY OTHER INTERACTIONS
          WITH USE OR WITH ANY OTHER USER OF THE SERVICE, WHETHER BASED ON
          WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL
          THEORY, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF
          SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE
          FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS LIMIT OR DO NOT
          PERMIT DISCLAIMERS OF LIABILITY, SO THIS PROVISION MAY NOT APPLY TO
          YOU.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          If you have a dispute with one or more users, a restaurant or a
          merchant of a product or service that you review using the Service,
          you release us (and our officers, directors, agents, subsidiaries,
          joint ventures and employees) from claims, demands and damages (actual
          and consequential) of every kind and nature, known and unknown,
          arising out of or in any way connected with such disputes.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          If you are a California resident using the Service, you may
          specifically waive California Civil Code &sect;1542, which says:
          &ldquo;A general release does not extend to claims which the creditor
          does not know or suspect to exist in his favor at the time of
          executing the release, which if known by him must have materially
          affected his settlement with the debtor.&rdquo;
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">INDEMNIFICATION</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          You hereby indemnify us to the fullest extent&nbsp;from and against
          any and all liabilities, costs, demands, causes of action, damages,
          and expenses (including reasonable attorney&rsquo;s fees) arising out
          of or in any way related to your breach of any of the provisions of
          these Terms.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">MODIFICATION OF TERMS OF USE</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          We can amend these Terms of Use at any time and will update these
          Terms of Use in the event of any such amendments. It is your sole
          responsibility to check the Site from time to time to view any such
          changes in this agreement. Your continued use of the Site or the
          Service signifies your agreement to our revisions to these Terms of
          Use. We will endeavor to notify you of material changes to the Terms
          by posting a notice on our homepage and/or sending an email to the
          email address you provided to us upon registration. For this
          additional reason, you should keep your contact and profile
          information current. Any changes to these Terms (other than as set
          forth in this paragraph) or waiver of our rights hereunder shall not
          be valid or effective except in a written agreement bearing the
          physical signature of one of our officers. No purported waiver or
          modification of this agreement on our part via telephonic or email
          communications shall be valid.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">SEVERABILITY</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          If any part of this Terms of Use agreement is held or found to be
          invalid or unenforceable under any applicable law, such
          unenforceability or invalidity shall not render these Terms
          unenforceable or invalid as a whole, and such provisions shall be
          deleted without affecting the remaining provisions herein; thus all
          remaining portions of the agreement will remain in full force and
          effect. Any failure on our part to enforce any provision of this
          agreement will not be considered a waiver of our right to enforce such
          provision. Our rights under this agreement survive any transfer or
          termination of this agreement.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          You agree that any cause of action related to or arising out of your
          relationship with us must commence within ONE year after the cause of
          action accrues. Otherwise, such cause of action is permanently barred.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">GOVERNING LAW &amp; JURISDICTION</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          Users in the United States: These Terms of Use and your use of the
          Site and/or application will be governed by and construed in
          accordance with the laws of the State of California, and you must
          submit to the non-exclusive jurisdiction of the state and federal
          courts located in California for the resolution of any disputes.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          Users located outside the United States: These Terms of Use of this
          Site and/or our application are governed by the federal laws of the
          United States of America and the laws of the State of California
          without regard to conflict of law provisions. In general, and to all
          users, any claim relating to SWAPNOW&#39;s website and/or application
          shall be governed by the laws of the state of California without
          regard to its conflict of law provisions. General Terms and Conditions
          are applicable to the Use of a Web Site.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c2">ASSIGNMENT</span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          We may assign or delegate these Terms of Service and/or our Privacy
          Policy, in whole or in part, to any person or entity at any time with
          or without your consent. You may not assign or delegate any rights or
          obligations under the Terms of Service or Privacy Policy without our
          prior written consent, and any unauthorized assignment or delegation
          by you is void.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5" id="h.30j0zll">
        <span className="c2">
          BY USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS
          OF USE, UNDERSTAND THE TERMS OF USE, AND WILL BE BOUND BY THESE TERMS
          AND CONDITIONS. YOU FURTHER ACKNOWLEDGE THAT THESE TERMS OF USE
          TOGETHER WITH THE PRIVACY POLICY AT{" "}
        </span>
        <span className="c11 c7">
          <a
            className="c6"
            href="https://projectthaihub.com/swapnow/privacy-policy"
          >
            PRIVACY POLICY
          </a>
        </span>
        <span className="c2">&nbsp;</span>
        <span className="c2">
          REPRESENT THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT
          BETWEEN US AND THAT IT SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL
          OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN US RELATING TO THE
          SUBJECT MATTER OF THIS AGREEMENT.
        </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">&nbsp; </span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0"></span>
      </p>
    </div>
  );

  return <div className="termstyle">{app}</div>;
}

export default TermAgreement;
