import React from "react";
import Contact from "./Contact/Contact";
import Intro from "./Intro/Intro";
import Services from "./Services/Services";

function Body() {
	const intro = <Intro />;
	const services = <Services />;
	const contact = <Contact />;

	const app = (
		<div className="mt-5">
			{intro}
			{services}
			{contact}
		</div>
	);

	return app;
}

export default Body;
